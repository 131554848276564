<template>
    <v-col cols="12" class="pt-0 px-0" id="detection-section">
        <v-card min-width="100%" class="mx-auto mb-6">
            <v-toolbar :color="color" :style="`color: ${textColor}`" elevation="3" class="scan-title-bar">
                <v-toolbar-title class="text-section-title">Detection Images</v-toolbar-title>
            </v-toolbar>
            <div class="d-flex pa-5 justify-center" v-if="scanLoading">
                <template v-for="(item, index) in Array.from(Array(11).keys())">
                    <v-skeleton-loader :key="index" style="width: 100px; height: 100px" v-bind="{ class: 'mx-2', elevation: 1 }"
                        type="image">
                    </v-skeleton-loader>
                </template>
            </div>
            <template v-if="!scanLoading && selectedFile.length == 0">
                <div class="ma-0 pa-5 pb-7 text-center">
                    No Data Available!.
                </div>
            </template>
            <v-sheet elevation="0" class="d-flex flex-wrap pa-0  mt-4" v-else-if="!scanLoading && selectedFile">
                <v-slide-group mandatory show-arrows >
                    <v-slide-item v-for="(image, index) in selectedFile" :key="index">
                        <v-menu  offset-y :disabled="selectOpt">
                            <template v-slot:activator="{ on, attrs }">
                                <div class="image-view-container mx-2 mb-3" v-bind="attrs" v-on="on" :class="selectOpt ? 'image-edit-static' : 'image-edit-hover'">
                                     <v-lazy @click="selectOpt && imageSelected(image.path)">
                                        <img height="100" width="100" contain :id="`annoatate-${index}-${image.id}`" :src="image.src" class="ma-2 cursor-pointer"
                                            crossorigin="anonymous" v-bind="attrs" v-on="on"/>
                                     </v-lazy>
                                     <div class="pinned pl-1" v-if="selectOpt">
                                        <div class="d-flex justify-end align-center position-relative">
                                            <v-icon  size="25" color="info" @click="imageSelected(image.path)" class="select-img">
                                                {{`mdi-${ mailSelectedImage.indexOf(image.path) !== -1 ? 'check-circle' : 'circle-outline'}`}}
                                            </v-icon>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <v-list class="scan-edit-options">
                                <v-list-item class="cursor-pointer option" @click="show(image.src)">
                                    <v-list-item-title>
                                        <v-icon :color="color" class="mr-2">mdi-archive-eye-outline </v-icon>
                                        View
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item class="cursor-pointer option" @click="openCrop = image.src">
                                    <v-list-item-title>
                                        <v-icon :color="color" class="mr-2">mdi-crop</v-icon>
                                        Crop
                                    </v-list-item-title>
                                </v-list-item>
                                <v-list-item class="cursor-pointer option" @click="showMarkerArea(`annoatate-${index}-${image.id}`)">
                                    <v-list-item-title>
                                        <v-icon :color="color" class="mr-2">mdi-image-plus </v-icon>
                                        Annotation
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-slide-item>
                </v-slide-group>
            </v-sheet>
            <CropperModal :openCrop="openCrop" v-if="!scanLoading && selectedFile && openCrop" @cancel="openCrop = null"
                @cropped="$emit('editedAnnotate', $event); openCrop = null" :name="'Cropped'"/>
        </v-card>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import * as markerjs2 from "markerjs2";

export default {
    props: ['selectedFile', 'selectOpt', 'color', 'textColor'],
    emits: ['editedAnnotate', 'mailSelectImg'],
    components: {
        CropperModal: () => import("./CropperModal.vue"), 
    },
    data: (vm) => ({
        openCrop: null,
        mailSelectedImage: []
    }),
    computed: {
        ...mapGetters(["scanLoading"]),
    },
    methods: {
        show(value) {
            const image = [];
            image.push(value);
            this.$viewerApi({
                images: image
            })
        },
        showMarkerArea(imgId) {
            const target = document.getElementById(imgId);
            const markerArea = new markerjs2.MarkerArea(target);
            markerArea.uiStyleSettings.zoomOutButtonVisible = true;
            markerArea.uiStyleSettings.zoomButtonVisible = true;
            markerArea.renderImageType = 'image/jpeg';
            markerArea.settings.displayMode = "popup";
            markerArea.addEventListener("render", (event) => {
                this.$store.commit('setPageLoading', false);
                this.$emit('editedAnnotate', {value: event.dataUrl, option: 'Annotated'});
            });
            markerArea.show();
        },
        imageSelected(image) {
            const imageIndex = this.mailSelectedImage.findIndex(img => img == image);
            if(imageIndex == -1) this.mailSelectedImage.push(image);
            else this.mailSelectedImage.splice(imageIndex, 1);
            this.$emit('mailSelectImg', this.mailSelectedImage);
        }
    }
}
</script>